<template>
</template>
  
<script>
    import { createNamespacedHelpers } from 'vuex'
    const AuthStore = createNamespacedHelpers('auth')

    export default {
        methods: {
            ...AuthStore.mapActions([
                "setAdminSession"
            ])
        },

        async mounted () {
            console.log("admin mounted", this.$route)
            const { access_token, expires_in, refresh_token, admin_token } = this.$route.query
            if (!access_token || !expires_in || !refresh_token || !admin_token) {
                alert("Error No Token")
                return
            }

            this.setAdminSession({ access_token, expires_in, refresh_token, admin_token })

            window.location.replace(window.location.origin)
        }
    }
</script>

<style lang="scss" scoped>
</style>
  